<template>
	<div
		class=""
	>
		<div
			class=""
		>
			<div class="justify-space-between-pc gap-20">
				<div class="hide-pc mb-20">
					<div class="mt-20 size-px-18 font-weight-bold color-dark">
						{{ item.pdt_name }}
					</div>
					<div class="mt-10 size-px-14 color-gray">
						{{ item.pdt_summary }}
					</div>
				</div>
				<div
					class="pdt-img1 square-360-pc box"
				>
					<div class=" justify-space-between full-height">
						<v-icon
							@click="file_index--"
							class="pa-10"
						>mdi mdi-chevron-left</v-icon>

						<div
							class="flex-column justify-center"
							style="text-align: center; min-width: 120px; min-height: 120px"
						>
							<template
								v-if="files.sub.length > 0"
							>
								<template
									v-for="(file, index) in files.sub"
								>
									<div
										v-show="file_index == index"
										:key="file.file_name"
										class="width-100 overflow-hidden"
									>
										<img
											:src="file.file_path" alt="main1"
											class="object-cover"
											@error="$bus.$emit('onErrorImage', $event)"
										/>
									</div>
								</template>
							</template>

							<img
								v-else
								:src="require('@/assets/images/common/empty-image.png')" alt="main1"
								class="object-cover"
								@error="$bus.$emit('onErrorImage', $event)"
							/>
						</div>
						<v-icon
							@click="file_index++"
							class="pa-10"
						>mdi mdi-chevron-right</v-icon>
					</div>
				</div>

				<hr class="hide-pc under-line-primary mt-20  "/>

				<div class="flex-1 top-line flex-column justify-space-between text-18 size-px-14-m color-dark">
					<div>
						<div class="mt-20 size-px-24 font-weight-bold hide-m">
							{{ item.pdt_name }}
						</div>
						<div class="mt-10 size-px-14 color-gray hide-m">
							{{ item.pdt_summary }}
						</div>
						<div
							class="mt-50-pc mt-20-m flex-row justify-space-between items-center under-line-dashed pb-10 "
						>
							<div
								class="font-weight-bold"
							>평점 {{ item.pdt_grade }}</div>
							<div
								class="pdt-price"
							>
								<template
									v-for="no in 5"
								>
									<v-icon
										:key="'star_' + no"
										:class="item.pdt_grade >= no ? 'color-yellow' : ''"
									>mdi mdi-star</v-icon>
								</template>
							</div>
						</div>

						<div
							class="mt-10 flex-row justify-space-between items-center under-line-dashed pb-10 "
						>
							<div
								class="font-weight-bold"
							>판매가</div>
							<div
								class=""
							>{{  item.pdt_price | makeComma }} 원</div>
						</div>

						<div
							v-if="item.is_regular == '1'"
							class="mt-10 flex-row justify-space-between under-line-dashed pb-10 "
						>
							<div
								class="font-weight-bold"
							>정기구매 가능 상품</div>
							<div
								class="pdt-price"
								@click="setBuy('bill')"
							><button class="label label-primary size-px-12"><v-icon class="color-white mr-5" small>mdi-arrow-left-bold-box-outline</v-icon>정기구매</button></div>
						</div>

						<div
							v-if="item.pdt_delivery > 0"
							class="mt-10 justify-space-between under-line-dashed pb-10"
						>
							<div
								class="font-weight-bold"
							>상품 개별 배송비</div>
							<div
								class="pdt-price"
							>{{ item.pdt_delivery | makeComma}} 원</div>
						</div>

						<div
							v-if="item.is_sold == 2"
							class="mt-10 flex-row justify-space-between under-line-dashed pb-10"
						>
							<div
								class="pdt-price"
							><span v-if="item.pdt_stock < 10" class="color-red">품절임박</span></div>
							<div
								class="pdt-rate"
							>수량 {{ item.pdt_stock | makeComma }} 개</div>
						</div>

						<div
							v-if="pdt_options.length > 0"
							class="mt-10 justify-space-between under-line-dashed pb-10"
						>

							<div
								class="font-weight-bold"
							>선택 옵션</div>
							<div
								class="flex-1"
							>
								<div
									v-for="(pdt_option, index) in pdt_options"
									:key="'option_' + index"
									class="text-right "
								>
									<select
										v-model="option[index]"
										class="size-px-14 pa-5-10 width-50"
										:class="{'mt-10' : index >0}"
									>
										<option value="">{{ pdt_option.opt_name }}</option>
										<option
											v-for="opt in pdt_option.opt_cont.split(',')"
											:key="'option_' + index + '_' + opt"
											:value="pdt_option.opt_name + ' : ' + opt"
										>{{ opt }}</option>
									</select>
								</div>
							</div>
						</div>
						<!-- 선택 옵션 -->
						<div
							class="mt-10 flex-row justify-space-between items-center under-line-dashed pb-10 "
						>
							<div
								class="font-weight-bold"
							>주문 수량</div>
							<ul
								v-show="is_show_options"
							>
								<li
									v-for="(odt, index) in odts"
									:key="'odt_' + index"
									class="under-line-dashed"
								>
									<div
										class="justify-space-between-pc"
									>
										<span
											class="flex-2 color-dark span-pdt-cnt text-14 size-px-12-m mr-5-pc"
										>{{ odt.odt }}</span>

										<span
											v-if="item.is_sold == 1 || (item.is_sold == 2 && item.pdt_stock < 1)"
											class=" color-red ml-10"
										>품절</span>
										<div
											v-else
											class="flex-column justify-center gap-5"
										>
											<div class="justify-end items-center">
												<button
													@click="setCnt(odt, 'down')"
													class="mdi mdi-minus box prl-5 "
												></button>
												<div><input v-model="odt.odt_cnt" :rules="$rules.max(odt, 'odt_cnt', 3)" class="box text-center " style="width: 50px; padding: 5px 0" type="number" @change="odt.odt_cnt < 1 ? odt.odt_cnt = 1 : odt.odt_cnt"/></div>
												<button
													@click="setCnt(odt, 'up')"
													class="mdi mdi-plus box prl-5"
													:class="{ 'mr-5': pdt_options.length}"
												></button>
												<button
													small
													v-if="pdt_options.length"
													@click="removeItem(index)"
													class="color-red mdi mdi-close"
												></button>
											</div>
										</div>
									</div>
								</li>
							</ul>
						</div>
					</div>
					<div
						class="mt-auto text-right top-line pt-20"
					>
						<div
							class="flex-row justify-space-between"
						>
							<span
								class="font-weight-bold color-dark"
							>총 상품가</span>
							<span
								class="order-price font-weight-bold color-dark"
							>{{ total_price | makeComma }} 원</span>
						</div>

						<div class="mt-40 hide-m">
							<button
								class="btn btn-fill mr-10"
								@click="setBuy('buy')"
							>바로구매</button>
							<button
								v-if="item.pdt_div != 'mobile'"
								class="btn btn-outline mr-10"
								@click="setCart"
							>장바구니</button>
							<button
								class=" box pa-10 radius-5"
								@click="postWish"
							><v-icon :class="{'color-red' : item.is_wish}">mdi-heart</v-icon></button>
						</div>

						<div class="mt-40 hide-pc justify-space-between-m gap-10">
							<button
								class="btn btn-fill btn-s flex-2"
								@click="setBuy('buy')"
							>바로구매</button>
							<button
								v-if="item.pdt_div != 'mobile'"
								class="btn btn-outline btn-s flex-2"
								@click="setCart"
							>장바구니</button>
							<button
								class=" box pa-10 radius-5 flex-1"
								@click="postWish"
							><v-icon :class="{'color-red' : item.is_wish}">mdi-heart</v-icon></button>
						</div>
					</div>
				</div>
			</div>


			<div class="mt-50-pc mt-30-m size-px-16 pdt-info ">
				<div
					id="menu_0"
					class="justify-space-between"
				>
					<h6
						v-for="(menu, m_index) in items_menu"
						:key="'menu_' + m_index"
						class="text-center flex-1 pa-20-pc ptb-10-m "
						:class="{ on: m_index == 0}"
						@click="toMenu(m_index)"
					>{{ menu }}</h6>
				</div>
				<div
					class="input-box pdt-info text-center overflow-hidden"
				>
					<div
						class=""
						v-html="item.pdt_info ? item.pdt_info.replaceAll('\n', '<br/>') : ''"
					></div>
					<Viewer
						v-if="false"
						:initialValue="item.pdt_info"
					/>
					<div
						v-if="files.file.length > 0"
					>
						<div
							v-for="(file, index) in files.file"
							:key="'file_' + index"
							class="overflow-hidden"
						><img :src="file.file_path" style="max-width: 100%"/></div>
					</div>
					<div
						v-else
						class="min-height-150 flex-column justify-center full-height items-center"
					>상품 정보가 없습니다</div>
				</div>

				<div
					id="menu_1"
					class="justify-space-between"
				>
					<h6
						v-for="(menu, m_index) in items_menu"
						:key="'menu_' + m_index"
						class="text-center flex-1 pa-20-pc ptb-10-m "
						:class="{ on: m_index == 1}"
						@click="toMenu(m_index)"
					>{{ menu }}</h6>
				</div>
				<div
					class="input-box pdt-notice  "
				>
					<div
						v-if="files.delivery.length > 0"
					><img :src="files.delivery.file_path" /></div>
					<div
						v-else
						class="flex-column justify-center min-height-150 items-center"
					>교환/반품 정보가 없습니다</div>
				</div>


				<div
					id="menu_2"
					class="justify-space-between"
				>
					<h6
						v-for="(menu, m_index) in items_menu"
						:key="'menu_' + m_index"
						class="text-center flex-1 pa-20-pc ptb-10-m "
						:class="{ on: m_index == 2}"
						@click="toMenu(m_index)"
					>{{ menu }}</h6>
				</div>
				<div
					class="input-box pdt-notice "
				>
					<div
						v-if="items_qna.length > 0"
					>
						<table class="table">
							<tbody>
								<template
									v-for="(qna, q_index) in list_qna"
								>
									<tr
										:key="'qna_' + q_index"
									>
										<td
											class="td-left"
										>
											<div class="justify-space-between gap-10">
												<div>
												[<span  :class="'color-' + qna.status_color">{{ qna.status_name }}</span>]
												{{ qna.b_contents}}
												</div>
												<button
													v-if="qna.b_answer"
													@click="$set(qna, 'is_on_answer', !qna.is_on_answer)"
												>
													<v-icon
														v-if="qna.is_on_answer"
													>mdi-menu-up</v-icon>
													<v-icon
														v-else
													>mdi-menu-down</v-icon>
												</button>
											</div>
										</td>
									</tr>
									<tr
										v-if="qna.is_on_answer"
										:key="'ans_' + q_index"
									>
										<td class="bg-gray-light td-left">{{ qna.b_answer }}</td>
									</tr>
								</template>
							</tbody>
						</table>

						<Pagination
							:program="program"
							:align="'center'"
							:options="item_search_qna"

							@click="getQna"

							class="mt-auto pa-10"
						></Pagination>
					</div>
					<div
						v-else
						class="min-height-150 flex-column justify-center items-center"
					>등록된 QnA가 없습니다.</div>
					<div class="text-right mt-10">
						<button
							class="btn-round btn-outline btn-ss-m"
							@click="onQna"
						>QnA 작성</button>
					</div>
				</div>


				<div
					id="menu_3"
					class="justify-space-between"
				>
					<h6
						v-for="(menu, m_index) in items_menu"
						:key="'menu_' + m_index"
						class="text-center flex-1 pa-20-pc ptb-10-m "
						:class="{ on: m_index == 3}"
						@click="toMenu(m_index)"
					>{{ menu }}</h6>
				</div>
				<div
					class="input-box pdt-notice mb-30 "
				>
					<div
						v-if="items_review.length > 0"
					>
						<table class="table">
							<colgroup>
								<col width="140px" />
								<col width="auto" />
							</colgroup>
							<tbody>
								<template
									v-for="(review, index) in items_review"
								>
									<tr
										:key="'review_' + index"
									>
										<td rowspan="2">
											<img :src="review.pdt_info.pdt_img1" class="width-100" />
										</td>
										<td class="td-left">

											<div class="justify-space-between">
												<div>
													<v-icon
														v-for="no in 5"
														:key="'star_' + no"
														:class="review.b_point >= no ? 'color-yellow' : ''"
														small
													>mdi mdi-star</v-icon>
												</div>
												<div class="hide-m">{{ review.wDate }}</div>
											</div>
										</td>
									</tr>
									<tr
										:key="'reviewt_' + index"
									>
										<td class="td-left" >
											<div>{{review.b_contents}}</div>
										</td>
									</tr>
								</template>
							</tbody>
						</table>
						<Pagination
							:program="program"
							:align="'center'"
							:options="item_search"

							@click="getReview"

							class="mt-auto pa-10"
						></Pagination>
					</div>
					<div
						v-else
						class="min-height-150 flex-column justify-center items-center"
					>등록된 리뷰가 없습니다.</div>
				</div>
			</div>

		</div>

		<div
			v-if="false"
			class="mt-auto pa-10 shadow-top position-relative"
		>
			<div
				v-if="options.length > 1"
				class="text-center"
				style="height: 10px;"
				@click="is_show_options = !is_show_options"
			>
				<v-icon
					v-if="is_show_options"
					class=" bg-white"
					style="border: 1px solid #ddd; margin-top: -50px"
				>mdi mdi-chevron-double-down</v-icon>
				<v-icon
					v-else
					class=" bg-white"
					style="border: 1px solid #ddd; margin-top: -50px"
				>mdi mdi-chevron-double-up</v-icon>
			</div>

			<!-- 선택 옵션 -->
			<ul
				v-show="is_show_options"
			>
				<li
					v-for="(odt, index) in odts"
					:key="'odt_' + index"
					class="pa-10 under-line-dashed"
				>
					<div
						class=" flex-row justify-space-between"
					>
						<span
							class="flex-2 color-dark span-pdt-cnt size-px-11 mr-5"
						>{{ odt.odt }}</span>

						<span
							v-if="item.is_sold == 1 || (item.is_sold == 2 && item.pdt_stock < 1)"
							class=" color-red ml-10"
						>품절</span>
						<span
							v-else
							class="flex-1  flex-column justify-center"
						>
							<span class="justify-space-between box-pdt-cnt">
								<button
									@click="setCnt(odt, 'down')"
									class="flex-1 mdi mdi-minus"
								></button>
								<input
									v-model="odt.odt_cnt"
									type="number"
									name="pdt_cnt"
									class="flex-1 bg-gray-light"
									readonly
								/>
								<button
									@click="setCnt(odt, 'up')"
									class="flex-1 mdi mdi-plus"
									:class="{ 'mr-5': pdt_options.length}"
								></button>
								<button
									small
									v-if="pdt_options.length"
									@click="removeItem(index)"
									class="flex-1 color-red mdi mdi-close"
								></button>
							</span>
						</span>
					</div>
				</li>
			</ul>

			<div
				class="pdt-cnt flex-row justify-space-between"
			>
				<span
					class="font-weight-bold color-dark"
				>총 상품가</span>
				<span
					class="order-price font-weight-bold color-dark"
				>{{ total_price | makeComma }}</span>
			</div>
			<div
				class="mt-10 flex-row justify-space-between"
			>
				<button
					class="flex-1 btn btn-default mr-10"
					@click="setBuy('buy')"
				>바로구매</button>
				<button
					class="flex-1 btn btn-identify"
					@click="setCart"
				>장바구니</button>
			</div>
		</div>
		<Modal
			:option="modal_option"
			:is_modal="is_modal"

			@close="toClose"
		>
			<div
				slot="modal-content"
				v-html="modal_option.content"
			></div>
			<template slot="modal-bottom">
				<div
					class="justify-space-between"
				>
					<button
						class="bg-default flex-1 pa-10-20"
						@click="toCart"
					>장바구니로 이동</button>
					<button
						class="bg-primary color-white flex-1 pa-10-20"
						@click="toClose"
					>계속 쇼핑</button>
				</div>
			</template>
		</Modal>

		<popup-confirm
			v-if="is_on_auth"

			@click="toLogin"
			@cancel="is_on_auth = false"
		>
			<template
				v-slot:title
			>로그인 안내</template>
			<template
				v-slot:main-txt
			>상품 구매는 로그인 후 가능합니다</template>
		</popup-confirm>

		<PopupLayer
			v-if="is_on_qna"
		>
			<template
				v-slot:body
			>
				<div class=" bg-white radius-top-20">
					<div class="pa-20 bg-popup-title flex-row justify-space-between items-center">
						<h4>QnA 등록</h4>
						<v-icon
							@click="is_on_qna = false"
						>mdi-close-circle</v-icon>
					</div>

					<div class=" pa-10">
						<textarea
							v-model="item_qna.b_contents"
							class="input-box"
							rows="10"
							:rules="$rules.max(item_qna, 'b_contents', 200)"
						></textarea>
					</div>
					<div class="pa-10 text-center">
						<button
							class="btn btn-outline "

							@click="postQna"
						>확인</button>
					</div>
				</div>
			</template>
		</PopupLayer>
	</div>
</template>

<script>

	import '@toast-ui/editor/dist/toastui-editor-viewer.css';
	import { Viewer } from "@toast-ui/vue-editor";

	import Modal from "@/components/Modal";
	import PopupConfirm from "@/view/Layout/PopupConfirm";
	import Pagination from "../../components/Pagination";
	import PopupLayer from "../Layout/PopupLayer";
	export default {
		name: 'ProductDetail'
		,props: ['user']
		,components: {PopupLayer, Pagination, PopupConfirm, Modal, Viewer}
		,data: function(){
			return {
				program: {
					name: '상품 상세'
					, top: false
					, title: true
					, bottom: true
					, top_line: true
				}
				,item: {}
				// 상품옵션
				,pdt_options: []
				// 판매자 정보
				,shop_info: {
					
				}
				//장바구니
				,cart_items: [
					
				]
				,pdt_cnt: 1
				,files: {
					main: []
					, sub: []
					, file: []
					, delivery: []
				}

				,file_index: 0
				// 현재 옵션
				,option: []
				// 선택한 옵션
				,options: [

				]
				,is_show_options: true
				// 장바구니 이동 컨펌창
				,is_modal: false
				,modal_option: {
					top: true
					,title: '장바구니'
					,content: '장바구니에 등록되었습니다. 장바구니로 이동하시겠습니까?'
					,bottom: true
				}
				, items_menu: [
					'상세 정보', '교환/반품', 'QnA', '리뷰'
				]
				, items_review: []
				, item_search: {
					page: 1
					, list_cnt: 10
					, page_cnt: 4
				}
				, item_search_qna: {
					page: 1
					, list_cnt: 10
					, page_cnt: 4
				}
				, items_qna: []
				, is_on_auth: false
				, is_on_qna: false
				, item_qna: {

				}
			}
		}
		,computed: {
			total_price: function(){
				let price = 0
				let agency_sale_price = this.item.pdt_price
				this.options.forEach(function(item){
					price += Number(item.odt_cnt) * (Number(agency_sale_price) + Number(item.odt_price))
				})
				return price
			}
			,odts: function(){
				return this.options.filter(function(item){
					return item.odt.replaceAll(',', '/')
				})
			}
			, list_qna: function(){
				return this.items_qna.filter((item) => {
					if(item.b_answer){
						item.status_name = '답변 완료'
						item.status_color = 'green'
					}else{
						item.status_name = '답변 대기'
					}
					return item
				})
			}
		}
		,methods: {
			getData: async function(){
				try{
					const result = await this.$request.init({
						method: 'get'
						, url: 'product/getProduct'
						, data: {
							pdt_uid: this.$route.params.idx
						}
					})

					if (result.success) {
						this.item = result.data.pdt_info
						this.$set(this, 'pdt_options', result.data.pdt_options)

						this.resetOption(this.pdt_options)
						this.files = result.data.pdt_files
						this.shop_info = result.data.shop_info
					} else {
						this.$bus.$emit('notify', {type: 'error', message: result.message})
					}
				}catch(E){
					console.log(E)
				}
			}
			,setBuy: async function(type) {
				if(!this.user || !this.user.member_id){
					this.is_on_auth = true
					return false
				}
				if(this.options.length <= 0){
					this.$bus.$emit('notify', { type: 'error', message: '옵션을 선택하세요'})
					return false
				}

				let item = this.item
				item.options = JSON.stringify(this.options)
				try{
					const result = await this.$request.init({
						method: 'post'
						,url: 'order/postBuy'
						,data: item
					})

					if(result.success){
						if(type == 'bill'){
							this.$bus.$emit('to', { name: 'OrderBill'})
						}else{
							this.$bus.$emit('to', { name: 'OrderBuy'})
						}
					}else{
						throw result.message
					}
				}catch (e) {
					console.log(e)
					this.$bus.$emit('notify', { type: 'error', message: e })
				}
			}

			,setCart: async function(){
				if(this.options.length <= 0){
					this.$bus.$emit('notify', { type: 'error', message: '옵션을 선택하세요'})
					return false
				}
				let item = this.item
				item.options = JSON.stringify(this.options)
				try{
					const result = await this.$request.init({
						method: 'post'
						,url: 'order/postCart'
						,data: item
					})

					if(result.success){

						this.$emit('getCartList')
						this.resetOption(this.option)

						this.is_modal = true
					}else{
						throw result.message
					}
				}catch (e) {
					console.log(e)
					this.$bus.$emit('notify', { type: 'error', message: e })
				}
			}
			,toBack: function(){
				this.$emit('toBack')
			}
			,toggleOption: function(option){
				this.$set(option, 'is_view', !option.is_view)
			}
			,setOption: function(option, opt){
				this.$set(option, 'opt', opt)
				this.toggleOption(option)
			}
			,resetOption: function(call){
				if(call.length > 0) {
					for (let i = 0; i < call.length; i++) {
						this.$set(this.option, i, '')
					}
				}else{
					this.$set(this.options, 0, {
						odt: this.item.pdt_name
						,odt_cnt: 1
						,odt_price: 0
						,pdt_stock: this.item.pdt_stock
					})
				}
			}
			,removeItem: function(index){
				if(confirm('해당 옵션을 삭제하시겠습니까?')){
					this.$delete(this.options, index)
				}
			}
			,toCart: function(){
				if(this.item.pdt_div){
					this.$bus.$emit('to', { name: 'Cart', params: { pdt_div: this.item.pdt_div}})
				}else{
					this.$bus.$emit('to', { name: 'Cart'})
				}
			}
			,toClose: function(){
				this.is_modal = false
			}
			,setCnt: function(odt, type){
				let max = 999
				if(this.item.pdt_div == 'mobile'){
					max = 10
				}
				if(this.item.limit_count){
					max = this.item.limit_count
				}
				if(type == 'down'){
					if(odt.odt_cnt <= 1){
						odt.odt_cnt = 1
						return
					}else{
						odt.odt_cnt--
					}
				}else{
					if(odt.odt_cnt >= max){
						odt.odt_cnt = max
						return
					}else{
						odt.odt_cnt++
					}
				}

				if(this.shop_info?.is_payment_limit == 1){
					let max = this.shop_info.payment_limit
					let agency_sale_price = this.item.pdt_price
					if(odt.odt_cnt * (Number(agency_sale_price) + Number(odt.odt_price)) >= max){
						this.$bus.$emit('notify', { type: 'error', message: '1회 구매 한도를 초과하였습니다.'})
						odt.odt_cnt--
					}
				}
			}
			, toLogin: function(){
				this.$bus.$emit('to', { name: 'Login'})
			}
			, postWish: async function(){
				try{
					const result = await this.$request.init({
						method: 'post'
						,url: 'member/postWish'
						,data: {
							pdt_uid: this.item.uid
						}
					})

					if(result.success){
						this.$set(this.item, 'is_wish', !this.item.is_wish)
						this.$bus.$emit('notify', { type: 'success', message: result.message})
					}else{
						throw result.message
					}
				}catch (e) {
					console.log(e)
					this.$bus.$emit('notify', { type: 'error', message: e })
				}
			}

			, getReview: async function(){
				try{
					const result = await this.$request.init({
						method: 'get'
						,url: 'board/getBbsList'
						,data: {
							pdt_uid: this.$route.params.idx
							, page: this.item_search.page
							, list_cnt: this.item_search.list_cnt
							, b_code: 'b_after'
						}
					})

					if(result.success){
						this.items_review = result.data.result
						this.$set(this.item_search, 'tCnt', result.data.tCnt)
					}else{
						throw result.message
					}
				}catch (e) {
					console.log(e)
					this.$bus.$emit('notify', { type: 'error', message: e })
				}
			}

			, getQna: async function(){
				try{
					const result = await this.$request.init({
						method: 'get'
						,url: 'board/getBbsList'
						,data: {
							pdt_uid: this.$route.params.idx
							, page: this.item_search_qna.page
							, list_cnt: this.item_search_qna.list_cnt
							, b_code: 'b_qna'
						}
					})

					if(result.success){
						this.items_qna = result.data.result
						this.$set(this.item_search_qna, 'tCnt', result.data.tCnt)
					}else{
						throw result.message
					}
				}catch (e) {
					console.log(e)
					this.$bus.$emit('notify', { type: 'error', message: e })
				}
			}
			, toMenu: function(menu){
				let d = document.getElementById('menu_' + menu)
				let t = d.offsetTop;

				document.body.scrollTo({top: t, behavior: 'smooth'})
			}
			, onQna: function(){
				this.is_on_qna = true
			}
			, postQna: async function(){
				try{
					const result = await this.$request.init({
						method: 'post'
						,url: 'board/postBbs'
						,data: {
							pdt_uid: this.$route.params.idx
							, b_title: '상품 문의 [' + this.item.pdt_name + ']'
							, b_contents: this.item_qna.b_contents
							, b_code: 'b_qna'
							, m_name: this.user.member_name
						}
					})

					if(result.success){
						this.is_on_qna = false
						this.item_qna = {}
						await this.getQna()
					}else{
						throw result.message
					}
				}catch (e) {
					console.log(e)
					this.$bus.$emit('notify', { type: 'error', message: e })
				}
			}
		}
		, created() {
			this.$bus.$emit('onLoad', this.program)
			this.getData()
			this.getReview()
			this.getQna()
		}
		,watch:{
			/*
			pdt_options: {
				deep: true
				,handler: function (call){
					this.resetOption(call)
				}
			}

			,*/ option: {
				deep: true
				,handler: function (call){
					let full = true

					// 전체 옵션 선택여부 체크
					call.forEach(function(item){
						if(!item){
							full = false
							return false
						}
					})

					// 전체 옵션 선택시 상품으로 추가
					if(full){
						let val = {
							odt: call.toString()
							,odt_price: 0
							,odt_cnt: 1
						}

						let result = this.options.filter(function(item){
							if(item.odt == val.odt){
								return item.odt_cnt++
							}
						})

						if(result.length){
							result.odt_cnt++
						}else{
							this.options.push(val)
						}

						this.resetOption(this.option)
					}
				}
			}
			,file_index: {
				handler: function(call){
					if(this.files.sub.length > 0) {
						if (call < 0) {
							this.file_index = this.files.sub.length - 1
						} else if (call >= this.files.sub.length) {
							this.file_index = 0
						}
					}
				}
			}
		}
	}

</script>

<style type="text/css">
.pdt-title {
	padding: 0;
	font-size: 14px;
	color: black;
}

.pdt-price {
	font-size: 16px;
	color: black;
	font-weight: bold;
	font-family: Helvetica,Apple-Gothic,Dotum,"돋움",Gulim,"굴림";
}



.pdt-option:first-child,
.li-pdt-option:first-child {

}

.pdt-option {
	border: 1px solid #bbb;
	border-radius: 2px;
	margin-top: 10px;
	padding: 10px;
}

.pdt-option .option-name {
	color: black;
	font-weight: bold;
}

.ul-pdt-option {
}

.li-pdt-option {
	border: 1px solid #ddd;
	border-radius: 10px;
	padding: 10px;
	margin-bottom: 10px;
}

.li-pdt-option:active {
	background-color: #eee;
}

.pdt-cnt {
	padding: 10px;
	border: 1px solid #ddd;
}
.box-pdt-cnt {
}

.box-pdt-cnt > button {
	border: 1px solid #ddd;
	width: 100%;
	font-size: 20px;
}
.box-pdt-cnt > input {
	border: none;
	border-top: 1px solid #ddd;
	border-bottom: 1px solid #ddd;
	padding: 0px 5px;
	width: 100%;
	text-align: center;
}

.left,
.right {
	background: none !important;
}

.carousel-control {
	color: black;
}

.shadow-top {
	box-shadow: 0px 2px 5px black;
}

.span-pdt-cnt {
	display: inline !important; line-height: 240%;
}

.select {
	width: 100%;
	padding: 10px;
	color: black;
}


.pdt-info img,
.pdt-notice img {
	max-width: 100%;
}

.pdt-info .on {
	color: var(--primary);
	border-bottom: 2px solid var(--primary) !important;
}
</style>
